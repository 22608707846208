// Prizes

.prizes {
  opacity: 1 !important;
  width: px(508) !important;
  min-height: px(706) !important;
  padding: px(55) px(10) px(50) px(10) !important;
  box-shadow: none !important;
  background: url('/img/bg-popup-sm.png?ver=1.1') 0 0/100% 100% no-repeat !important;
  filter: drop-shadow(0 0 px(15) #005dff);
  &:after {
    display: none !important;
  }

  &__name, &__list, &__message, &__price {
    width: px(385);
    margin-left: auto;
    margin-right: auto;
  }

  &__name {
    color: $white;
    font-size: px(42);
    font-family: $font-family-svn;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-bottom: 0;
    text-shadow: px(3) px(3) px(10) #0051f4, px(-2) px(-2) px(10) #0051f4;
  }

  &__price {
    font-size: px(24);
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: px(10);
    margin-top: px(5);
  }

  &__message {
    color: $white;
    font-size: px(17);
    font-weight: 300;
    line-height: 1.1em;
    margin-bottom: px(10);
  }

  &__list {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid #ff7100;
  }

  &__item {
    height: px(48);
    display: flex;
    align-items: center;
    padding-left: px(60);
    position: relative;

    &:nth-child(even) {
      // background: rgba($colorPurple2, .5);
      background: #803900;
    }

    &--image {
      position: absolute;
      top: calc(50% - #{px(29/2)});
      left: px(15);
      height: px(29);
    }

    &--name {
      font-size: px(16);
      font-weight: 300;
    }
  }
}
