// Fonts

@font-face {
  font-family: 'UTM Bebas';
  src: url('#{$cdn}/fonts/UTMBebas.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Justice League';
  src: url('#{$cdn}/fonts/SVN-JusticeLeague.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'iCiel Gotham Ultra';
  src: url('#{$cdn}/fonts/iCielGotham-Ultra.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FIFAnybody Condensed';
  src: url('/fonts/FIFAnybody-CondensedBlack.eot');
  src: url('/fonts/FIFAnybody-CondensedBlack.eot?#iefix') format('embedded-opentype'),
      url('/fonts/FIFAnybody-CondensedBlack.woff2') format('woff2'),
      url('/fonts/FIFAnybody-CondensedBlack.woff') format('woff'),
      url('/fonts/FIFAnybody-CondensedBlack.ttf') format('truetype'),
      url('/fonts/FIFAnybody-CondensedBlack.svg#FIFAnybody-CondensedBlack') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FIFAnybody ExtraBold';
  src: url('/fonts/FIFAnybody-ExtraBold.eot');
  src: url('/fonts/FIFAnybody-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/FIFAnybody-ExtraBold.woff2') format('woff2'),
      url('/fonts/FIFAnybody-ExtraBold.woff') format('woff'),
      url('/fonts/FIFAnybody-ExtraBold.ttf') format('truetype'),
      url('/fonts/FIFAnybody-ExtraBold.svg#FIFAnybody-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FIFAnybody SemiBoldItalic';
  src: url('/fonts/FIFAnybody-SemiBoldItalic.eot');
  src: url('/fonts/FIFAnybody-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/FIFAnybody-SemiBoldItalic.woff2') format('woff2'),
      url('/fonts/FIFAnybody-SemiBoldItalic.woff') format('woff'),
      url('/fonts/FIFAnybody-SemiBoldItalic.ttf') format('truetype'),
      url('/fonts/FIFAnybody-SemiBoldItalic.svg#FIFAnybody-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
