// Sweetalert2

.swal2-container {
  z-index: 1070 !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba($black, .9) !important;
}

.swal2-popup {
  width: px(508) !important;
  height: px(706) !important;
  border-radius: 0 !important;
  padding: 0 px(70);
  background: url('#{$cdn}/img/bg-popup-sm.png?ver=1.0') 0 0/100% 100% no-repeat !important;

  .swal2-title {
    font-size: px(38);
    line-height: 1.1em !important;
    text-transform: uppercase;
    color: $white !important;
    font-family: $font-family-fifa-extra;
    font-style: italic;
    margin: 0 0 px(15);
    text-shadow: px(3) px(3) px(10) #0051f4, px(-2) px(-2) px(10) #0051f4;
  }

  .swal2-header, .swal2-content {
    padding: 0 !important;
  }

  .swal2-actions {
    margin: px(20) 0 0;
    width: auto;

    .swal2-confirm, .swal2-cancel {
      border: 0 !important;
      margin: 0 px(10) !important;
      padding: px(6) 0 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      text-transform: uppercase !important;
      font-size: px(22) !important;
      font-style: italic !important;
      font-family: $font-family-fifa-extra !important;
      line-height: 1.2em;
      width: px(160);
      height: px(66);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $white !important;
      background: url('/img/btn-price.png') 0 0/100% 100% no-repeat !important;
    }
  }

  .swal2-close {
    width: auto;
    height: auto;
    color: transparent;
    outline: none;
    display: none;
    box-shadow: none !important;
  }

  .popup-alert {
    &__message {
      font-size: px(24);
      line-height: 1.1em !important;
      text-transform: uppercase;
      color: $white !important;
      font-family: $font-family-fifa-semi;
      font-style: italic;
      text-shadow: px(3) px(3) px(10) #ff7100, px(-2) px(-2) px(10) #ff7100;
    }

    &__image {
      text-align: center;
      margin-top: px(35);

      img {
        max-height: px(270);
      }
    }
  }

  &.popup-no-title {
    .swal2-title {
      display: none !important;
    }
  }

  &.popup-success {

  }

  &.popup-error {

  }

  &.popup-confirm {

  }
}
