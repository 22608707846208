#footer {
  // position: relative;
  // background: #000;
  // padding-top: 1.5vw;
  // padding-bottom: 1.5vw;
  // .container {
  //   width: px(1320);
  // }
  // .footer-logo {
  //   display: inline-block;
  //   width: px(500);
  //   margin-bottom: px(18);
  // }

  // p {
  //   font-size: px(17);
  //   text-transform: uppercase;
  //   color: #fff;
  //   text-align: center;
  //   margin-bottom: 0;
  // }
  img {
    width: 100%;
  }
}
