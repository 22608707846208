// Popup purchase

.modal-content {
  .popup-purchase {
    $self: ".popup-purchase";

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &__title {
      color: $white;
      font-size: px(45);
      font-family: $font-family-svn;
      text-transform: uppercase;
      text-shadow: px(3) px(3) px(10) #0051f4, px(-2) px(-2) px(10) #0051f4;
    }

    &__image {
      margin: px(5) 0;

      img {
        height: px(220);
      }
    }

    &__msg {
      color: $white;
      font-size: px(22);
      font-weight: 300;
      font-family: $font-family-cond;
      text-transform: uppercase;

      strong {
        font-weight: 700;
      }
    }

    &__reward {
      font-size: px(16);
      font-style: italic;
      color: #feab2e;
      padding: 0 px(15);
    }

    &__btn {
      display: flex;
      align-items: center;
      // justify-content: center;
      text-align: center;
      width: px(420);
      height: px(130);
      color: $white !important;
      text-transform: uppercase;
      // font-style: italic;
      position: relative;
      padding-top: px(5);
      padding-left: px(45);
      margin: px(10) auto 0;
      background: url("#{$cdn}/img/btn-buy-right.png") 0 0/100% 100% no-repeat;
      animation: twinkle2 500ms infinite;
    }

    &__label {
      font-size: px(19.75);
      line-height: 1em;
      text-align: right;
      font-family: $font-family-iciel;
    }

    &__price {
      font-size: px(56);
      font-family: $font-family-fifa;
      line-height: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: px(4);
      width: px(220);
      padding-top: px(8);
      span {
        font-size: px(80);
        line-height: 1em;
      }
    }

    &__save {
      position: absolute;
      top: px(6);
      left: px(45);
      font-size: px(18);
      line-height: 1.1em;
      font-family: $font-family-utm;
      width: px(220);
    }

    &__time {
      font-size: px(20);
      // font-style: italic;
      font-family: $font-family-utm;
      color: #f39825;
      span {
        display: inline-block;
        font-size: px(16);
        padding: px(3) px(10) px(5) px(10);
        line-height: 1;
        color: $white;
        background: #c54600 !important;
        background: linear-gradient(
          to top,
          #c54600 0%,
          #ffad2f 100%
        ) !important;
        border-radius: px(15);
        -webkit-background-clip: inherit !important;
        -webkit-text-fill-color: inherit !important;
      }
    }

    &[data-index="0"] {
      #{$self}__label,
      #{$self}__price {
        // text-shadow: px(-2) px(-2) px(10) $colorRed2, px(2) px(2) px(10) $colorRed2;
        @include gradientY(#ffa923, #ff7a30);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      // #{$self}__time {
      //   color: $colorRed3;
      // }
    }

    &[data-index="1"] {
      #{$self}__label,
      #{$self}__price {
        // text-shadow: px(-2) px(-2) px(10) $colorBlue, px(2) px(2) px(10) $colorBlue;
        @include gradientY(#ffa923, #ff7a30);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      // #{$self}__time {
      //   color: $colorBlue2;
      // }
    }
    &[data-index="2"] {
      #{$self}__label,
      #{$self}__price {
        // text-shadow: px(-2) px(-2) px(10) $colorBlue, px(2) px(2) px(10) $colorBlue;
        @include gradientY(#ffa923, #ff7a30);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      // #{$self}__time {
      //   color: $colorBlue2;
      // }
    }
    &[data-index="3"] {
      #{$self}__label,
      #{$self}__price {
        // text-shadow: px(-2) px(-2) px(10) $colorBlue, px(2) px(2) px(10) $colorBlue;
        @include gradientY(#ffa923, #ff7a30);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      // #{$self}__time {
      //   color: $colorBlue2;
      // }
    }
    &__type {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: px(10);
      font-size: px(28);
      font-family: $font-family-svn;
      text-transform: uppercase;
      text-shadow: px(3) px(3) px(10) #0051f4, px(-2) px(-2) px(10) #0051f4;
      margin-left: px(8);
      color: $white;
      img {
        width: px(37);
      }
      a {
        display: inline-block;
        color: $white;
        font-size: px(28);
        font-family: $font-family-svn;
        text-transform: uppercase;
        text-shadow: px(3) px(3) px(10) #0051f4, px(-2) px(-2) px(10) #0051f4;
        margin-left: px(8);
      }
    }
  }
}
