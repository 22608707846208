// Animations

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes twinkle {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(px(-12));
  }
}

@keyframes twinkle3 {
  0%, 100% {
    filter: none;
  }
  50% {
    filter: drop-shadow(0 0 px(10) #ff4e00);
  }
}
@keyframes twinkle2 {
  0%, 100% {
    filter: none;
  }
  50% {
    filter: drop-shadow(0 0 px(10) #1478ff);
  }
}
