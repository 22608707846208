// Home

.home {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  &__cta {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 200;
    margin: px(-66) auto 0;
    width: px(260);
    height: px(40);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: px(18);
    font-style: italic;
    font-family: $font-family-iciel;
    color: $white !important;
    text-transform: uppercase;
    line-height: 1.2em;
    padding-top: px(20);
    text-shadow: px(3) px(3) px(10) rgba(255, 198, 171, 0.5), px(-2) px(-2) px(10) rgba(255, 198, 171, 0.5);
    background: url('#{$cdn}/img/btn-cta.png') 0 0/100% 100% no-repeat;
    transition: all 300ms ease;

    &:hover {
      filter: drop-shadow(0 0 px(5) rgba(255, 198, 171, 0.5));
    }
  }
}
