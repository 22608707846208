// Base

html {
  font-size: 6px;

  @include media-breakpoint-up(md) {
    font-size: 8px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 10px;
  }
}

a {
  text-decoration: none !important;
}

* {
  outline: none !important;
}

.bg-image {
  background-size: cover;
  background-color: $gray-300;
  background-repeat: no-repeat;
  background-position: center center;
}

.__react_component_tooltip {
  opacity: 1 !important;
  font-size: px(16);
  padding: px(10) px(15);
  max-width: px(570);
  box-shadow: 0 0 px(10) $black;
}

#main {
  position: relative;

  .background {
    width: 100%;
    user-select: none;
    pointer-events: none;
  }

  &>.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
