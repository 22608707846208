// Modal

.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  z-index: 1060 !important;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
  background: rgba($black, 0.8) !important;
}

.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  background: transparent;
  border: 0 !important;
  padding: 0 !important;
  outline: none;
  transform: translateY(-30%);
  transition: all 500ms ease;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;

  &--after-open {
    transform: translateY(0);
    opacity: 1;
  }

  &--before-close {
    transform: translateY(-30%);
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .modal-body {
      overflow: hidden;
      will-change: transform;
      height: 100%;

      .modal-content {
        width: 100%;
        height: 100%;

        & > .spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .close {
    display: none;
  }

  // Default
  &.modal-theme-default {
    width: px(1222) !important;
    height: px(730) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url("#{$cdn}/img/bg-popup.png?ver=1.0") 0 0/100% 100%
          no-repeat;
      }
    }
  }

  // Small
  &.modal-theme-small {
    width: px(508) !important;
    height: px(706) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url("#{$cdn}/img/bg-popup-sm.png?ver=1.0") 0 0/100% 100%
          no-repeat;
      }
    }
  }

  // Guide
  &.modal-theme-guide {
    width: px(1510) !important;
    height: px(830) !important;

    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url("https://cdn-fo4.garenanow.com/games/fo4vn/externalAssets/webbanner/MonthlyT82023_HD.png")
          0 0/100% 100% no-repeat;
      }
    }

    .btn-detail {
      display: inline-block;
      width: px(190);
      height: px(66);
      line-height: px(68);
      text-align: center;
      text-transform: uppercase;
      font-size: px(20);
      color: $white;
      font-weight: bold;
      font-style: italic;
      background: url("/img/btn-price.png") no-repeat;
      background-size: 100% 100%;
      font-family: $font-family-fifa-extra;
      position: absolute;
      bottom: px(35);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // History
  &.modal-theme-history {
    width: px(1510) !important;
    height: px(820) !important;
    .modal-wrapper {
      .modal-body {
        position: relative;
        background: url("#{$cdn}/img/bg-popup.png?ver=1.0") 0 0/100% 100%
          no-repeat;
      }
    }
  }
}
.popup-guide-text {
  padding: px(120) px(170) 0 px(170);

  &__title {
    color: $white;
    font-size: px(50);
    font-family: $font-family-svn;
    text-shadow: px(3) px(3) px(10) #0051f4, px(-2) px(-2) px(10) #0051f4;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: px(-10);
  }
  h3 {
    font-size: px(24);
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
  }
  p {
    font-size: px(18);
    margin-bottom: px(5);
    color: #fff;
  }
  ul {
    li {
      font-size: px(18);
      margin-bottom: px(8);
    }
  }
}
