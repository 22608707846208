// Purchased

.purchased {
  width: px(1385);
  min-height: px(690);
  position: absolute;
  top: px(140);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: px(75);
  padding-bottom: px(85);
  background: url('#{$cdn}/img/bg-popup.png?ver=1.0') 0 0/100% 100% no-repeat;

  &__title {
    width: px(1082);
    margin: 0 auto px(15);
    text-align: left;
    padding-bottom: px(10);
    display: flex;
    align-items: flex-end;

    &>img {
      height: px(95);
    }

    &>a {
      display: inline-block;
      margin-bottom: px(20);

      &>img {
        height: px(40);
      }
    }

    &--sub {
      font-size: px(18);
      font-weight: 300;
      font-style: italic;
      padding: 0 0 px(10) px(20);
    }
  }

  .swiper-container {
    .swiper-button-prev,
    .swiper-button-next {
      width: px(20);
      height: px(50);
      position: absolute;
      top: calc(50% - #{px(50/2)});
      z-index: 10;

      &.swiper-button-disabled {
        opacity: .5;
      }
    }

    .swiper-button-prev {
      left: px(-2);
      background: url('#{$cdn}/img/icon-prev.png') 0 0/100% 100% no-repeat;
    }

    .swiper-button-next {
      right: px(-2);
      background: url('#{$cdn}/img/icon-next.png') 0 0/100% 100% no-repeat;
    }
  }

  &__list {
    text-align: center;
    width: px(1150);
    margin: 0 auto;

    &--empty {
      font-size: px(30);
      margin-top: px(180);
    }
  }

  &__item {

    &--odd {
      .purchased__item--time {
        border-top: px(2) solid #905d1b;
        border-bottom: px(2) solid #905d1b;
        background: rgb(250,204,34);
        background: linear-gradient(to bottom, rgba(250,204,34,0) 0%, rgba(248,54,0,0.1) 100%);
        span {
          background: #c54600 !important;
          background: linear-gradient(to top, #c54600 0%, #ffad2f 100%) !important;
        }
      }
      .purchased__item--claim {
        background: url('/img/btn-price1.png') 0 0/100% 100% no-repeat;
      }
    }

    &--image {
      img {
        height: px(231);
      }
    }

    &--name {
      font-size: px(16);
      padding: 0 px(10);
      // font-style: italic;
      font-family: $font-family-roboto;
      font-weight: 500;
      line-height: 1.1em;

      span {
        font-size: px(16);
        display: inline-block;
        padding-right: px(6);
        @include gradientY(#fff0ad, #c0a757);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &--claim {
      display: flex;
      align-items: center;
      justify-content: center;
      width: px(205);
      height: px(56);
      color: $white !important;
      font-size: px(22);
      font-style: italic;
      font-family: $font-family-fifa-extra;
      text-transform: uppercase;
      line-height: 1.2em;
      padding-top: px(6);
      margin: px(23) auto 0;
      background: url('/img/btn-price.png') 0 0/100% 100% no-repeat;
      transition: all 300ms ease;

      &:hover {
        filter: drop-shadow(0 0 px(10) #3bf7f1);
      }

      &.disabled {
        cursor: no-drop;
        filter: grayscale(0.5) brightness(0.5) !important;
      }
    }

    &--time {
      font-size: px(14);
      font-family: $font-family-roboto;
      margin: 0 auto;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      width: px(240);
      border-top: px(2) solid #1c3fa7;
      border-bottom: px(2) solid #1c3fa7;
      padding: px(7) 0;
      background: rgb(50,140,215);
      background: linear-gradient(to bottom, rgba(50,140,215,0) 0%, rgba(49,238,250,0.1) 100%);
      span {
        display: inline-block;
        font-size: px(12);
        padding: 0 px(10);
        color: $white;
        background: rgb(50,140,215) !important;
        background: linear-gradient(to top, #0147e7 0%, #09cedc 100%) !important;
        border-radius: px(15);
        -webkit-background-clip: inherit !important;
        -webkit-text-fill-color: inherit !important;

      }
    }

    &--limit {
      color: $white;
      margin-top: px(3);
      text-transform: uppercase;
      font-size: px(14);
      font-weight: 500;
    }
  }
}
