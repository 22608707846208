// Shop

.shop {
  position: absolute;
  top: px(380);
  left: 0;
  right: 0;
  z-index: 300;
  width: px(1330);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: center;
  &--two-row {
    top: px(220);
    flex-wrap: wrap;
  }

  &__item {
    $self: '.shop__item';
    position: relative;
    width: px(640);
    &.four-item {
      width: px(550);
      #{$self}--image {
        img {
          height: px(300);
        }
      }
      #{$self}--name-reward {
        font-size: px(12);
        line-height: 1.4;
        p{
          &:first-child {
            font-size: px(20);
            span {
              font-size: px(20);
            }
          }
        }
      }
      #{$self}--content {
        bottom: px(-110);
      }
      #{$self}--tag {
        top: px(85);
        left: px(-120);
      }
      #{$self}--info {
        width: calc(100% - #{px(225)});
      }
      #{$self}--time {
        font-size: px(12);
        span {
          font-size: px(12);
        }
      }
    }
    &:hover {
      #{$self}--image {
        animation-name: tada;
        animation-duration: 2000ms;
      }
    }

    &--image {
      margin-top: px(-10);
      position: relative;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-name: twinkle;
      display: inline-block;
      img {
        height: px(400);
        &.img-right {
          // height: px(335);
          margin-left: 0;
        }
      }
    }

    &--tag {
      position: absolute;
      top: px(110);
      left: px(-106);
      width: px(160);
      height: px(105);
      background: url(/img/price-tag.png) no-repeat;
      background-size: 100% 100%;
    }

    &--content {
      font-size: px(22);
      font-family: $font-family-utm;
      line-height: 1.1;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: px(-120);
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      span {
        font-size: px(20);
        display: inline-block;
        padding-right: px(10);

        em {
          font-family: $font-family-cond;
        }
      }
    }

    &--select {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: px(15);
      a {
        display: block;
        width: px(160);
        height: px(66);
        line-height: px(68);
        text-align: center;
        text-transform: uppercase;
        font-size: px(20);
        color: $white;
        font-weight: bold;
        font-style: italic;
        background: url('/img/btn-price.png')  no-repeat;
        background-size: 100% 100%;
        font-family: $font-family-fifa-extra;
        &.btn-price-1 {
          background: url('/img/btn-price1.png') no-repeat;
          background-size: 100% 100%;
        }
        &.btn-price-2 {
          background: url('/img/btn-price2.png') no-repeat;
          background-size: 100% 100%;
          color: #010101;
        }
        &.btn-price-3 {
          background: url('/img/btn-price3.png') no-repeat;
          background-size: 100% 100%;
          color: #010101;
        }
        &.disabled {
          cursor: no-drop;
          filter: grayscale(0.5) brightness(0.5) !important;
        }
      }
    }

    &--info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - #{px(205)});
    }

    &--name-reward {
      width: 100%;
      font-size: px(16);
      font-weight: 300;
      font-style: normal;
      text-align: left;
      font-family: $font-family-roboto;
      text-transform: uppercase;
      line-height: 1.4;
      p{
        margin-bottom: 0;
        &:first-child {
          font-size: px(26);
          font-weight: bold;
          font-family: $font-family-utm;
        }
        span {
          font-size: px(26);
        }
      }
    }

    &--purchase {
      display: flex;
      align-items: center;
      text-align: center;
      width: px(160);
      height: px(66);
      color: $white !important;
      text-transform: uppercase;
      font-style: italic;
      position: relative;
      background: url('#{$cdn}/img/btn-price.png') 0 0/100% 100% no-repeat;
      &.btn-price-1 {
        background: url('#{$cdn}/img/btn-price1.png') 0 0/100% 100% no-repeat;
      }
      &.btn-price-2 {
        background: url('#{$cdn}/img/btn-price2.png') 0 0/100% 100% no-repeat;
      }
      &.btn-price-3 {
        background: url('#{$cdn}/img/btn-price3.png') 0 0/100% 100% no-repeat;
      }

      &.disabled {
        cursor: no-drop;
        filter: grayscale(0.5) brightness(0.5) !important;
      }
    }

    &--label {
      font-size: px(19.75);
      line-height: 1em;
      text-align: right;
      font-family: $font-family-iciel;
    }

    &--price {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: px(8);
      font-size: px(33);
      font-weight: bold;
      font-family: $font-family-fifa;
      line-height: 1em;
      color: #000000;

      span {
        font-size: px(46);
        line-height: 1em;
      }
    }

    &--save {
      font-size: px(12);
      line-height: 1.1em;
      font-weight: bold;
      font-style: normal;
      background: rgb(50,140,215);
      background: linear-gradient(to bottom, #0147e7 0%, #09cedc 100%);
      padding: px(5) 0;
    }

    &--time {
      font-size: px(16);
      font-family: $font-family-roboto;
      margin-top: px(4);
      color: #fff;
      font-weight: 500;
      text-transform: uppercase;
      width: 100%;
      border-top: px(2) solid #1c3fa7;
      border-bottom: px(2) solid #1c3fa7;
      padding: px(10) 0;
      background: rgb(50,140,215);
      background: linear-gradient(to bottom, rgba(50,140,215,0) 0%, rgba(49,238,250,0.1) 100%);
      span {
        display: inline-block;
        font-size: px(16);
        padding: px(4) px(10);
        color: $white;
        background: rgb(50,140,215) !important;
        background: linear-gradient(to top, #0147e7 0%, #09cedc 100%) !important;
        border-radius: px(15);
        -webkit-background-clip: inherit !important;
        -webkit-text-fill-color: inherit !important;
        font-weight: bold;
      }
    }

    &[data-index="0"] {
      margin-bottom: px(100);
      &:hover {
        #{$self}--select {
          a {
            animation: twinkle2 500ms infinite;
          }
        }
      }
      &.four-item {
        #{$self}--content {
          margin-left: px(-20);
          bottom: px(-100);
          span {
            @include gradientY(#09cedc, #0147e7);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      #{$self}--content {
        margin-left: px(-20);
        bottom: px(-108);
        span {
          @include gradientY(#09cedc, #0147e7);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &[data-index="1"] {
      margin-bottom: px(110);
      &:hover {
        #{$self}--select {
          a {
            animation: twinkle3 500ms infinite;
          }
        }
      }

      #{$self}--time {
        border-top: px(2) solid #905d1b;
        border-bottom: px(2) solid #905d1b;
        background: rgb(250,204,34);
        background: linear-gradient(to bottom, rgba(250,204,34,0) 0%, rgba(248,54,0,0.1) 100%);
        span {
          background: #c54600 !important;
          background: linear-gradient(to top, #c54600 0%, #ffad2f 100%) !important;
        }
      }

      #{$self}--content {
        margin-right: px(-20);
        span {
          @include gradientY(#ffa923, #ff7a30);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      #{$self}--purchase {
        padding-left: inherit;
        padding-right: px(130);
      }
      #{$self}--save {
        background: rgb(50,140,215);
        background: linear-gradient(to bottom, #c54600 0%, #ffad2f 100%);

      }
    }

    &[data-index="2"] {
      margin-bottom: px(110);
      &:hover {
        #{$self}--select {
          a {
            animation: twinkle3 500ms infinite;
          }
        }
      }

      #{$self}--time {
        border-top: px(2) solid #21a8b0;
        border-bottom: px(2) solid #21a8b0;
        background: rgb(250,204,34);
        background: linear-gradient(to bottom, rgba(250,204,34,0) 0%, rgba(49,238,250,0.1) 100%);
        span {
          background: #c54600 !important;
          background: linear-gradient(to top, #00a2ad 0%, #35f7ff 100%) !important;
        }
      }

      #{$self}--content {
        margin-left: px(-20);
        bottom: px(-100);
        span {
          @include gradientY(#35f7ff, #00a2ad);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      #{$self}--purchase {
        padding-left: inherit;
        padding-right: px(130);
      }
      #{$self}--save {
        background: rgb(50,140,215);
        background: linear-gradient(to bottom, #00a2ad 0%, #35f7ff 100%);
        color: #000000;
      }
    }
    &[data-index="3"] {
      margin-bottom: px(110);
      &:hover {
        #{$self}--select {
          a {
            animation: twinkle3 500ms infinite;
          }
        }
      }

      #{$self}--time {
        border-top: px(2) solid #ae8927;
        border-bottom: px(2) solid #ae8927;
        background: rgb(250,204,34);
        background: linear-gradient(to bottom, rgba(250,204,34,0) 0%, rgba(255,193,0,0.1) 100%);
        span {
          background: #c54600 !important;
          background: linear-gradient(to top, #c39b20 0%, #ffcf3a 100%) !important;
        }
      }

      #{$self}--content {
        margin-right: px(-20);
        bottom: px(-100);
        span {
          @include gradientY(#fed965, #e7b619);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      #{$self}--purchase {
        padding-left: inherit;
        padding-right: px(130);
      }
      #{$self}--save {
        background: rgb(50,140,215);
        background: linear-gradient(to bottom, #e7b619 0%, #fed965 100%);
        color: #000000;
      }
    }
  }
}
.disable {
  filter: grayscale(0.5) brightness(0.5) !important;
}
