// Header

.header {
  text-align: center;
  width: 100%;
  height: px(111);
  background: url(/img/bg-header.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;

  &__logo-fo4,
  &__logo-event,
  &__left,
  &__right {
    position: absolute;
    z-index: 200;
  }

  &__logo-fo4,
  &__logo-event {
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &__logo-fo4 {
    top: px(30);
    width: px(271);
    filter: drop-shadow(0 0 px(10) $white);
  }

  &__logo-event {
    top: px(216);
    width: px(360);
    display: none;
  }

  &__left,
  &__right {
    top: px(25);
    width: px(485);
    height: px(69);
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0 0 px(10) $white);
  }

  &__left {
    left: px(140);
    // background: url('#{$cdn}/img/bg-header-left.png') 0 0/100% 100% no-repeat;
  }

  &__right {
    right: px(140);
    padding-right: px(59);
    // background: url('#{$cdn}/img/bg-header-right.png') 0 0/100% 100% no-repeat;
  }

  &__menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      line-height: 1.1em;

      & ~ li {
        position: relative;
        margin-left: px(40);

        &:before {
          content: "";
          width: px(2);
          height: px(17);
          display: block;
          background: $white;
          position: absolute;
          top: calc(50% - #{px(17/2)});
          left: px(-19);
          filter: drop-shadow(0 0 px(10) $white);
        }
      }

      a {
        display: inline-block;
        color: $white !important;
        font-size: px(20);
        font-weight: bold;
        font-family: $font-family-cond;
        text-transform: uppercase;
        line-height: 1.1em;
        transition: all 300ms ease;

        &:hover {
          text-shadow: 0 0 px(6) $black;
        }
      }
    }
  }

  &__login {
    width: px(155);
    height: px(40);
    font-size: px(20);
    display: block;
    color: $black !important;
    font-family: $font-family-roboto;
    background-color: $white;
    border-radius: px(20);
    line-height: px(42);
    text-transform: uppercase;
    font-weight: bold;
  }

  &__user {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      color: $white;
      font-family: $font-family-cond;
      line-height: 1.1em;
      font-size: px(20);
      text-transform: uppercase;

      & ~ li {
        position: relative;
        margin-left: px(20);

        &:before {
          content: "";
          width: px(2);
          height: px(17);
          display: block;
          background: $white;
          position: absolute;
          top: calc(50% - #{px(17/2)});
          left: px(-10);
        }
      }

      strong {
        font-weight: 700;
      }

      a {
        display: inline-block;
        margin-left: px(3);
        transform: translateY(px(-2));

        img {
          height: px(21);
        }

        &.loading {
          img {
            animation: rotating 1000ms infinite linear;
          }
        }
      }
    }
  }

  &__logout {
    width: px(42);
    height: px(42);
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    position: absolute;
    top: px(15);
    right: 0;
    border-radius: 50%;
    filter: drop-shadow(0 0 px(2) $white);

    img {
      height: px(27);
    }
  }
}
