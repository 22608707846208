// Scrollbar

.ScrollbarsCustom {
  $self: '.ScrollbarsCustom';
  $headSpace: px(0);
  $footSpace: px(0);
  $edgeSpace: px(0);
  $size: px(11);
  $backgroundColor: #ff7100;

  &-Track {
    background: none !important;
    border-radius: 0 !important;
  }

  &-Thumb {
    border-radius: 0 !important;
    background: $backgroundColor !important;
  }

  &.trackYVisible {
    #{$self}-Wrapper {
      right: 0 !important;
    }

    #{$self}-TrackY {
      right: $edgeSpace !important;
      width: $size !important;
      height: calc(100% - #{$headSpace} - #{$footSpace}) !important;
      top: $headSpace !important;

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        background: $backgroundColor;
      }
    }
  }

  &.trackXVisible {
    #{$self}-Wrapper {
      bottom: 0 !important;
    }

    #{$self}-TrackX {
      bottom: $edgeSpace !important;
      height: $size !important;
      width: calc(100% - #{$headSpace} - #{$footSpace}) !important;
      left: $headSpace !important;
    }
  }
}
