// Popup histories

.modal-content {
  .popup-histories {
    padding: px(190) px(244) 0 px(244);

    &__title {
      color: $white;
      font-size: px(50);
      font-family: $font-family-svn;
      text-shadow: px(3) px(3) px(10) #0051f4, px(-2) px(-2) px(10) #0051f4;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-left: px(-10);
    }

    &__table {
      height: px(474);

      .ScrollbarsCustom .ScrollbarsCustom-TrackY {
        right: px(-42) !important;
      }

      table {
        width: 100%;
        color: $white;
        font-size: px(20);
        text-align: center;
        text-transform: uppercase;

        th {
          height: px(58);
          font-weight: 700;
          // border-top: 2px solid $colorBlue4;
          border-bottom: 2px solid #ff7100;
          color: #ff7100;
        }

        td {
          height: px(61);
          font-weight: 300;
          border-bottom: 1px solid #ff7100;
        }
      }
    }
  }
}
