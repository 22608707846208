// Variables

$cdn: '';

$colorBlue: #0054ff;
$colorBlue2: #0060ff;
$colorBlue3: #216f82;
$colorBlue4: #02697f;
$colorRed: #b10b0d;
$colorRed2: #ff0000;
$colorRed3: #ff1e00;
$colorRed4: #ed1a51;
$colorPink: #ff00cf;
$colorPurple: #5a00af;
$colorPurple2: #4d0190;

$body-bg: $black;
$body-color: $white;

$font-size-base: 1.6rem;

$font-family-roboto: 'Roboto', sans-serif;
$font-family-cond: 'Roboto Condensed', sans-serif;
$font-family-utm: 'UTM Bebas', sans-serif;
$font-family-svn: 'SVN-Justice League', sans-serif;
$font-family-iciel: 'iCiel Gotham Ultra', sans-serif;
$font-family-fifa: 'FIFAnybody Condensed', sans-serif;
$font-family-fifa-extra: 'FIFAnybody ExtraBold', sans-serif;
$font-family-fifa-semi: 'FIFAnybody SemiBoldItalic', sans-serif;
$font-family-base: $font-family-roboto;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  xxxl: 1800px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
  xxxl: 1740px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");
